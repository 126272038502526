
export default {
  props: ['value'],
  data() {
    return {
      data: this.value
    }
  },
  watch: {
    value(neu) {
      this.data = neu;
    }
  },
  methods: {
    updateNummer(event) {
      // this.data.nummer = '0'+event.nationalNumber;
    }
  }
}
